import { Pipe, PipeTransform } from '@angular/core';
import startCase from 'lodash/startCase';
import camelCase from 'lodash/camelCase';
import snakeCase from 'lodash/snakeCase';
import lowerCase from 'lodash/lowerCase';

@Pipe({
  name: 'changeCase',
  standalone: true
})
export class ChangeCasePipe implements PipeTransform {
  transform(input: string, caseType: 'start' | 'camel' | 'snake' | 'lower'): string {
    switch (caseType) {
      case 'start':
        return startCase(input);

      case 'camel':
        return camelCase(input);

      case 'snake':
        return snakeCase(input);

      case 'lower':
        return lowerCase(input);

      default:
        return input;
    }
  }
}

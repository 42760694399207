import { animate, style, transition, trigger } from '@angular/animations';

const zeroOpacity = style({ opacity: 0 });
const enterAnim = animate('{{ enterMs }} ease-out');
const leaveAnim = animate('{{ leaveMs }} ease-in', zeroOpacity);
const enterMsParam = { enterMs: '150ms' };
const leaveMsParam = { leaveMs: '150ms' };

export const fadeInOutAnimation = trigger('fadeInOut', [
  transition(':enter', [zeroOpacity, enterAnim], {
    params: enterMsParam
  }),
  transition(':leave', [leaveAnim], {
    params: leaveMsParam
  })
]);

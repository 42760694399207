<section class="form-wrapper">
  <h2>Your booking details</h2>
  <form [formGroup]="bookingForm" (ngSubmit)="onSubmit()">
    <span class="p-float-label">
      <input
        pInputText
        id="firstname"
        name="firstname"
        formControlName="firstname"
        autocomplete="true"
      />
      <label for="firstname">First name</label>
    </span>

    <span class="p-float-label">
      <input
        pInputText
        id="lastname"
        name="lastname"
        formControlName="lastname"
        autocomplete="true"
      />
      <label for="lastname">Last name</label>
    </span>

    <span class="p-float-label">
      <input
        pInputText
        id="email"
        name="email"
        formControlName="email"
        type="email"
        autocomplete="true"
      />
      <label for="email">Email</label>
    </span>

    <span class="p-float-label">
      <input
        pInputText
        id="phone"
        name="phone"
        formControlName="phone"
        type="tel"
        autocomplete="true"
      />
      <label for="phone">Phone</label>
    </span>

    <p-dropdown
      name="country"
      [options]="countries"
      formControlName="country"
      optionLabel="name"
      [filter]="true"
      filterBy="name"
      [showClear]="true"
      placeholder="Country of origin"
    >
      <ng-template pTemplate="selectedItem" let-selectedOption>
        <div>{{ selectedOption.name }}</div>
      </ng-template>
      <ng-template let-country pTemplate="item">
        <div>{{ country.name }}</div>
      </ng-template>
    </p-dropdown>

    <div class="radio-group">
      <p>Who are you booking for?</p>
      <p-radioButton name="person" value="self" formControlName="person" inputId="self" />
      <label for="self" class="ml-2">Myself</label>
      <p-radioButton name="person" value="other" formControlName="person" inputId="other" />
      <label for="other" class="ml-2">Someone else</label>
    </div>

    <ng-container *ngIf="bookingForm.get('person')?.value === 'other'">
      <span class="p-float-label">
        <input
          pInputText
          id="guestFirstname"
          name="guestFirstname"
          formControlName="guestFirstname"
          type="text"
          autocomplete="true"
        />
        <label for="guestFirstname">Guest first name</label>
      </span>
      <span class="p-float-label">
        <input
          pInputText
          id="guestLastname"
          name="guestLastname"
          formControlName="guestLastname"
          type="text"
          autocomplete="true"
        />
        <label for="guestLastname">Guest last name</label>
      </span>
    </ng-container>

    <div class="radio-group">
      <p>Is this a business trip?</p>
      <p-radioButton name="business" value="true" formControlName="business" inputId="true" />
      <label for="true" class="ml-2">Yes</label>
      <p-radioButton name="business" value="false" formControlName="business" inputId="false" />
      <label for="false" class="ml-2">No</label>
    </div>

    <ng-container *ngIf="bookingForm.get('business')?.value === 'true'">
      <span class="p-float-label">
        <input
          pInputText
          id="company"
          name="company"
          formControlName="company"
          type="text"
          autocomplete="true"
        />
        <label for="company">Company name</label>
      </span>
      <span class="p-float-label">
        <input
          pInputText
          id="fiscal"
          name="fiscal"
          formControlName="fiscal"
          type="text"
          autocomplete="true"
        />
        <label for="fiscal">Company fiscal (optional)</label>
      </span>
      <span class="p-float-label">
        <input
          pInputText
          id="registry"
          name="registry"
          formControlName="registry"
          type="text"
          autocomplete="true"
        />
        <label for="registry">Company registry (optional)</label>
      </span>
    </ng-container>

    <p-button
      label="Pay"
      icon="pi pi-check"
      size="large"
      type="submit"
      [disabled]="bookingForm.invalid"
    />
  </form>
</section>

<p-tabView [scrollable]="true">
  <p-tabPanel *ngFor="let tab of availableUnits | keyvalue">
    <ng-template pTemplate="header">
      <span>{{ tab.key | prettyName: 'unit' | titlecase }}</span>
      <ng-container *ngIf="totalSelectedUnitsPerTypeMap[tab.key] as total">
        <p-badge [value]="total | toString" @fadeInOut />
      </ng-container>
    </ng-template>

    <ul>
      <li
        *ngFor="let unit of tab.value"
        class="unit-item"
        [class.selected]="isUnitSelected(unit.id, selectedUnitIds)"
      >
        <app-unit-details
          [unit]="unit"
          [selection]="selection"
          [selectedExtras]="getSelectedExtras(unit.id)"
          [isUnitSelected]="isUnitSelected(unit.id, selectedUnitIds)"
        />
        <div class="right-container">
          <div class="right-inner">
            <app-unit-images [unit]="unit" />
            <app-unit-price
              [unit]="unit"
              [selection]="selection"
              [selectedExtras]="getSelectedExtras(unit.id)"
              [isUnitSelected]="isUnitSelected(unit.id, selectedUnitIds)"
            />
          </div>
        </div>
      </li>
    </ul>
  </p-tabPanel>
</p-tabView>

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RippleModule } from 'primeng/ripple';
import { allCountries } from '../../../shared/constants/countries';

@Component({
  selector: 'app-checkout-form',
  standalone: true,
  imports: [
    DropdownModule,
    ReactiveFormsModule,
    InputTextModule,
    RadioButtonModule,
    ButtonModule,
    RippleModule,
    CommonModule
  ],
  templateUrl: './user-form.component.html',
  styleUrl: './user-form.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserFormComponent implements OnInit {
  countries = allCountries;
  bookingForm!: FormGroup;

  constructor() {
    this.bookingForm = new FormGroup({
      firstname: new FormControl(null, Validators.required),
      lastname: new FormControl(null, Validators.required),
      email: new FormControl(null, [Validators.required, Validators.email]),
      phone: new FormControl(null, Validators.required),
      country: new FormControl(null, Validators.required),
      person: new FormControl(null, Validators.required),
      business: new FormControl(null, Validators.required),
      guestFirstname: new FormControl(null),
      guestLastname: new FormControl(null),
      company: new FormControl(null),
      fiscal: new FormControl(null),
      registry: new FormControl(null)
    });
  }

  ngOnInit() {}

  // todo de facut validatoarele astea sa mearga ok
  validateGuest(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return this.bookingForm.get('person')?.value === 'other' && !control.value
        ? { guestIsMandatory: true }
        : null;
    };
  }

  validateCompany(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return this.bookingForm.get('business')?.value === 'true' && !control.value
        ? { companyIsMandatory: true }
        : null;
    };
  }

  onSubmit() {}
}

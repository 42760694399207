import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  BookingUnitExtra,
  fadeInOutAnimation,
  SearchBarSelectionInternal,
  Unit,
  UnitSelectedExtraFeatures
} from '@shared/';
import { AccordionModule } from 'primeng/accordion';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { TagModule } from 'primeng/tag';
import { TooltipModule } from 'primeng/tooltip';
import { ComputedPipe, ExtractViewPipe } from '../../../pipes';
import { StoreService } from '../../../services';

@Component({
  selector: 'app-unit-details',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ButtonModule,
    TooltipModule,
    ComputedPipe,
    TagModule,
    DividerModule,
    ExtractViewPipe,
    DialogModule,
    AccordionModule,
    BadgeModule,
    CalendarModule,
    ReactiveFormsModule
  ],
  templateUrl: './unit-details.component.html',
  styleUrl: './unit-details.component.scss',
  animations: [fadeInOutAnimation],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UnitDetailsComponent implements OnInit {
  @Input() unit!: Unit;
  @Input() selection: SearchBarSelectionInternal | null = null;
  @Input() isUnitSelected: boolean = false;
  @Input() selectedExtras!: BookingUnitExtra[];
  extrasForm!: FormGroup;
  isPerksDialogVisible: boolean = false;
  minDate!: Date;
  maxDate!: Date;

  constructor(private store: StoreService) {
    this.extrasForm = new FormGroup({});
  }

  ngOnInit() {
    this.unit.extraFeatures.forEach(extra => {
      this.extrasForm.addControl('date_' + extra.id, new FormControl(null));
    });

    if (this.selection && this.selection.startDate) {
      this.minDate = new Date(this.selection.startDate);
    }

    if (this.selection && this.selection.endDate) {
      this.maxDate = new Date(this.selection.endDate);
    }
  }

  showPerksDialog() {
    this.isPerksDialogVisible = true;
  }

  isExtraSelected(extraId: string): boolean {
    return !!this.selectedExtras?.find(item => item.id === extraId);
  }

  triggerExtrasChangeSelection(extraId: string, action: 'add' | 'remove'): void {
    if (action === 'add') {
      this.store.addUnitToSelection(this.unit.id);
      this.extrasForm.get('date_' + extraId)!.disable();

      return this.store.addExtraFeatureToUnitSelection(
        this.unit.id,
        extraId,
        this.extrasForm.get('date_' + extraId)!.value
      );
    } else {
      this.extrasForm.patchValue({
        ['date_' + extraId]: null
      });
      this.extrasForm.get('date_' + extraId)!.enable();

      return this.store.removeExtraFeatureFromUnitSelection(this.unit.id, extraId);
    }
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { ButtonModule } from 'primeng/button';
import dayjs from 'dayjs';
import { API_DATE_FORMAT, AutoUnsubscribe, City, SearchBarSelectionOutbound } from '@shared/';

function extractCityNames(cities: City[]): string[] {
  return cities.map(city => city.name);
}

function transformSearchQueryParams(
  searchTerms: SearchBarSelectionOutbound | null
): FormInterface | null {
  if (searchTerms) {
    const { city, maxAdultsCount, maxChildrenCount, startDate, endDate } = searchTerms;
    return {
      city,
      rangeDates: [new Date(startDate), new Date(endDate)],
      adultCount: parseInt(maxAdultsCount),
      childCount: maxChildrenCount ? parseInt(maxChildrenCount) : 0
    };
  } else return null;
}

const dateRangeValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const rangeDates = control.get('rangeDates')?.value;

  const startDate = rangeDates && rangeDates[0];
  const endDate = rangeDates && rangeDates[1];

  return !startDate || !endDate ? { noDateinterval: true } : null;
};

interface FormInterface {
  city: string;
  rangeDates: [Date | null, Date | null];
  adultCount: number;
  childCount: number;
}

@Component({
  selector: 'app-search-bar',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    AutoCompleteModule,
    CalendarModule,
    OverlayPanelModule,
    InputTextModule,
    InputNumberModule,
    ButtonModule
  ],
  templateUrl: './search-bar.component.html',
  styleUrl: './search-bar.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
@AutoUnsubscribe
export class SearchBarComponent implements OnInit {
  constructor(private formBuildier: FormBuilder) {
    this.searchForm = this.formBuildier.group({
      city: ['', Validators.required],
      rangeDates: [null, null],
      adultCount: [0, [Validators.min(1)]],
      childCount: 0
    });

    this.searchForm.setValidators(dateRangeValidator);

    this.searchForm.get('rangeDates')?.valueChanges.subscribe(values => {
      if (!values || !values.length) {
        this.calculateDateDisplayFormat(null, null);
      } else {
        this.calculateDateDisplayFormat(values[0], values[1]);
      }
    });
  }

  @Input({ transform: extractCityNames }) cities: any[] = [];
  @Input({ transform: transformSearchQueryParams }) set searchTerms(value: FormInterface | null) {
    if (value) this.searchForm.patchValue(value);
  }
  @Output() hubSearch = new EventEmitter<SearchBarSelectionOutbound>();
  searchForm: FormGroup;
  filteredCities: string[] = [];
  today: Date = new Date();
  isLoading: boolean = false;
  dateDisplayFormat: 'dd.mm' | 'dd.mm.yy' = 'dd.mm';

  ngOnInit() {}

  private calculateDateDisplayFormat(startDate: Date | null, endDate: Date | null): void {
    if (!startDate || !endDate) {
      this.dateDisplayFormat = 'dd.mm';
    } else {
      const yearDiff = dayjs(startDate)
        .startOf('year')
        .diff(dayjs(endDate).startOf('year'), 'year');
      this.dateDisplayFormat = yearDiff < 0 ? 'dd.mm.yy' : 'dd.mm';
    }
  }

  public filterCities({ query }: { query: string }) {
    this.filteredCities = this.cities.filter(
      city => city.toLowerCase().indexOf(query.toLowerCase()) === 0
    );
  }

  public triggerHubSearch() {
    const { city, rangeDates, adultCount, childCount } = this.searchForm.value;
    this.hubSearch.emit({
      city,
      maxAdultsCount: adultCount.toString(),
      maxChildrenCount: childCount.toString(),
      startDate: dayjs(rangeDates[0]).startOf('day').format(API_DATE_FORMAT),
      endDate: dayjs(rangeDates[1]).startOf('day').format(API_DATE_FORMAT)
    });
  }

  get adultCount(): string | null {
    return this.searchForm.get('adultCount')?.value;
  }

  get childCount(): string | null {
    return this.searchForm.get('childCount')?.value;
  }

  get isValid(): boolean {
    return this.searchForm.valid && !this.searchForm.hasError('noDateinterval');
  }
}

import { CommonModule } from '@angular/common';
import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { SearchBarSelectionInternal, UnitWithSelectedExtras } from '@shared/*';
import dayjs from 'dayjs';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { BehaviorSubject } from 'rxjs';
import { ComputedPipe } from '../../../pipes';
import { StoreService } from '../../../services';

function calculateFeaturesTotal(unit: UnitWithSelectedExtras) {
  return unit.extraFeatures.reduce((accum, elem) => {
    if (!elem.days?.length) return 0;
    return accum + elem.price * elem.days.length;
  }, 0);
}

@Component({
  selector: 'app-price-breakdown',
  standalone: true,
  imports: [DividerModule, DialogModule, CommonModule, ComputedPipe],
  templateUrl: './price-breakdown.component.html',
  styleUrl: './price-breakdown.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PriceBreakdownComponent implements OnInit {
  @Input() unitId: string | null | undefined;
  @Input({ transform: booleanAttribute }) isCheckout: string | boolean = false;
  protected readonly dayjs = dayjs;
  protected readonly calculateFeaturesTotal = calculateFeaturesTotal;
  isPriceBreakdownVisible: boolean = false;

  unitSubject$: BehaviorSubject<UnitWithSelectedExtras | undefined> = new BehaviorSubject<
    UnitWithSelectedExtras | undefined
  >(undefined);
  selectionSubject$: BehaviorSubject<SearchBarSelectionInternal | null> =
    new BehaviorSubject<SearchBarSelectionInternal | null>(null);
  nightsSubject$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  constructor(private store: StoreService) {}

  ngOnInit() {
    this.store.selectedUnitsWithSelectedExtras$.subscribe(units => {
      this.unitSubject$.next(units.find(unit => unit.id === this.unitId));
    });
    this.store.currentSelection$.subscribe(selection => this.selectionSubject$.next(selection));
    this.store.totalNightsSelection$.subscribe(value => this.nightsSubject$.next(value));
  }

  public openPriceBreakdown() {
    this.isPriceBreakdownVisible = !this.isPriceBreakdownVisible;
  }

  public getBookingDates(startDate: any, nights: number) {
    const dates = [];
    for (let i = 0; i < nights; i++) {
      dates.push(dayjs(startDate).add(i, 'day'));
    }
    return dates;
  }
}

import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { inject } from '@angular/core';
import { HubService } from '../../services';
import { SearchBarSelectionOutbound } from '@shared/';
import { map, zip } from 'rxjs';

export const AvailableHubResolver: ResolveFn<any> = (route: ActivatedRouteSnapshot) => {
  const params = route.params;
  const { startDate, endDate } = route.queryParams as SearchBarSelectionOutbound;
  const hubService = inject(HubService);
  const hub$ = hubService.getHub(params['hubId']);
  const availableUnits$ = hubService.getAvailableUnits(params['hubId'], {
    startDate,
    endDate
  });
  return zip(hub$, availableUnits$).pipe(
    map(([hub, availableUnits]) => ({
      hub,
      availableUnits
    }))
  );
};

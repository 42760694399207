import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'computed',
  standalone: true
})
export class ComputedPipe implements PipeTransform {
  transform(value: any, computeFc: (arg: any) => any): any {
    return computeFc(value);
  }
}

import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BehaviorSubject, map } from 'rxjs';
import { HubSummary, SearchBarSelectionInternal } from '@shared/*';
import { HubListComponent } from './hub-list/hub-list.component';
import { MapComponent } from '../common/map/map.component';
import { ActivatedRoute, Router } from '@angular/router';
import { StoreService } from '../../services';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [CommonModule, HubListComponent, MapComponent],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store: StoreService
  ) {}

  hubsSubject$: BehaviorSubject<HubSummary[]> = new BehaviorSubject<HubSummary[]>([]);
  searchTerms: SearchBarSelectionInternal | null = null;

  ngOnInit() {
    this.route.data
      .pipe(map(data => data['reqData']))
      .subscribe(hubs => this.hubsSubject$.next(hubs));
    this.store.currentSelection$.subscribe(
      selection =>
        (this.searchTerms = selection && Object.keys(selection).length ? (selection as any) : null)
    );
  }

  public async handleNavigateToHub(hubId: string) {
    if (this.searchTerms) {
      const { city, startDate, endDate, adultCount, childCount } = this.searchTerms;
      const queryParams = { city, startDate, endDate, maxAdultsCount: adultCount };
      if (childCount) Object.assign(queryParams, { maxChildrenCount: childCount });
      await this.router.navigate([`available-hub/${hubId}`], { queryParams });
    } else {
      await this.router.navigate([`hub/${hubId}`]);
    }
  }
}

import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { DivIcon, latLng, Marker, marker, tileLayer } from 'leaflet';
import { CommonModule } from '@angular/common';
import { HubSummary } from '@shared/*';

function extractHubPinDetails(hubs: HubSummary[]): HubPinDetails[] {
  return hubs.map(({ id, name, location }) => ({
    id,
    name,
    lat: location?.lat,
    lng: location?.lng
  }));
}

function extractMapPinHtml(hubName: string): string {
  return `<div class="map-pin-wrapper">
      <span class="map-pin-title">${hubName}</span>
      <span class="map-pin-icon material-symbols-outlined"> distance </span>
     </div>`;
}

interface HubPinDetails {
  id: string;
  name: string;
  lat: number | undefined;
  lng: number | undefined;
}

@Component({
  selector: 'app-map',
  standalone: true,
  imports: [CommonModule, LeafletModule],
  templateUrl: './map.component.html',
  styleUrl: './map.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapComponent {
  @Input({ transform: extractHubPinDetails }) set hubs(value: HubPinDetails[]) {
    this.buildMapLayers(value);
  }

  options = {
    layers: [
      tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 16,
        attribution: '...'
      })
    ],
    zoom: 10,
    center: latLng(44.43225, 26.10626)
  };

  layers: Marker[] = [];

  private buildMapLayers(hubPinDetails: HubPinDetails[]): void {
    this.layers = hubPinDetails.reduce((accum: Marker[], elem: HubPinDetails) => {
      if (elem.lat && elem.lng) {
        const mapPinHtml = extractMapPinHtml(elem.name);
        const markerOptions = {
          // icon: icon({
          //   iconSize: [30, 30],
          //   iconAnchor: [0, 0],
          //   shadowSize: [0, 0],
          //   iconUrl: `assets/icons/material_symbols_map_pin.svg`,
          //   className: 'map-pin'
          // }),
          icon: new DivIcon({ html: mapPinHtml, className: 'map-pin' }),
          title: elem.name,
          riseOnHover: true,
          riseOffset: 100,
          interactive: true,
          iconSize: [30, 30]
        };
        return [...accum, marker([elem.lat, elem.lng], markerOptions)];
      } else return accum;
    }, []);
  }
}

<section class="unit-price">
  <div class="inline-icon-item">
    <span class="material-symbols-outlined"> payments </span>
    <span class="bold"> {{ unit.averagePrice }} lei</span>
    <span>/ night</span>
  </div>

  <div class="booking-period" *ngIf="selection">
    <div>
      <p>CHECK-IN</p>
      <span>{{ startDate }}</span>
    </div>
    <div>
      <p>CHECK-OUT</p>
      <span>{{ endDate }}</span>
    </div>
    <div class="booking-guests">
      <p>GUESTS</p>
      <span>{{ adults }}</span>
      <span>{{ adults === '1' ? ' adult' : ' adults' }}</span>
      <ng-container *ngIf="children && children !== '0'">
        <span>{{ ', ' + children }}</span>
        <span>{{ children === '1' ? ' child ' : ' children ' }}</span>
      </ng-container>
    </div>
  </div>

  <p-button
    icon="pi pi-plus"
    label="Reserve"
    *ngIf="!isUnitSelected"
    (click)="triggerChangeSelection(unit.id, 'add')"
  />
  <p-button
    icon="pi pi-times"
    label="Cancel"
    severity="danger"
    *ngIf="isUnitSelected"
    (click)="triggerChangeSelection(unit.id, 'remove')"
  />

  <app-price-breakdown *ngIf="isUnitSelected" [unitId]="unit.id" />
</section>

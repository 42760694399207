<ng-container *ngIf="availableUnitsSubject$ | async as availableUnits">
  <app-units-list
    [selection]="selectionSubject$ | async"
    [availableUnits]="availableUnits"
    [processedSelectedUnits]="selectedUnitIdsSubject$ | async"
    [selectedUnitsSelectedExtras]="selectedUnitsSelectedExtrasSubject$ | async"
  />

  <ng-container *ngIf="selectedUnitsSelectedExtrasSubject$ | async as unitsWithExtras">
    <ng-container *ngIf="bookingTotalSubject$ | async as total">
      <div class="cart" *ngIf="unitsWithExtras.length" @fadeInOut>
        <div class="cart-inner">
          <p>
            <span>Total price: </span>
            <span class="highlighted"> {{ total + ' lei' }}</span>
          </p>

          <p-button
            [raised]="true"
            label="Finish booking"
            routerLink="/checkout"
            icon="pi pi-shopping-cart"
          />
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>

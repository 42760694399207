import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'extractView',
  standalone: true
})
export class ExtractViewPipe implements PipeTransform {
  transform(viewCode: number): string {
    if (viewCode) {
      switch (viewCode) {
        case 1:
          return 'North';
        case 2:
          return 'South';
        case 3:
          return 'East';
        case 4:
          return 'South';
        default:
          return '';
      }
    } else {
      return '';
    }
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { HubSummary } from '@shared/';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { environment } from '@env/';
import { BadgeModule } from 'primeng/badge';
import { GalleriaModule } from 'primeng/galleria';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'app-home-hubs-list',
  standalone: true,
  imports: [CommonModule, TooltipModule, BadgeModule, NgOptimizedImage, GalleriaModule],
  templateUrl: './hub-list.component.html',
  styleUrl: './hub-list.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HubListComponent implements OnInit {
  constructor() {}

  @Input() hubs: HubSummary[] = [];
  @Output() navigateToHub: EventEmitter<string> = new EventEmitter<string>();
  baseImageUrl: string = environment.baseImageUrl;

  ngOnInit() {}

  //TODO - de scos functia intr-un fisier global
  getPerkIcon(perk: string): string {
    switch (perk) {
      case 'Metrou':
        return 'train';
      case 'Parc':
        return 'nature';
      case 'Sală Fitness':
        return 'exercise';
      case 'Restaurant':
        return 'restaurant';
      case 'Centru':
        return 'holiday_village';
      case 'Pădure':
        return 'forest';
      case 'Lac':
        return 'kayaking';
      default:
        return 'add_diamond';
    }
  }

  triggerNavigateToHub(hubId: string) {
    this.navigateToHub.emit(hubId);
  }
}

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Hub, Unit } from '@shared/';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { ComputedPipe, ExtractUnitsTypePipe, PrettyNamePipe } from '../../../pipes';
import { MapComponent } from '../../common/map/map.component';
import { HubCalendarComponent } from '../calendar/hub-calendar.component';

@Component({
  selector: 'app-hub-details',
  standalone: true,
  imports: [
    CommonModule,
    DividerModule,
    ComputedPipe,
    PrettyNamePipe,
    ExtractUnitsTypePipe,
    MapComponent,
    ButtonModule,
    HubCalendarComponent,
    DialogModule
  ],
  templateUrl: './hub-details.component.html',
  styleUrl: './hub-details.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HubDetailsComponent {
  @Input() hub!: Hub;
  @Input() availableUnits!: Unit[];
  @Input() hasSelection: boolean = false;
  perksVisible: boolean = false;
  isDescriptionLong: boolean = false;
  descVisible: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) {}

  showPerksDialog() {
    this.perksVisible = true;
  }

  getDisplayedDescription(description: string): string {
    if (description.length > 340) {
      this.isDescriptionLong = true;
      return description.slice(0, 340) + ' ...';
    } else return description;
  }

  showDescDialog() {
    this.descVisible = true;
  }

  async navigateToUnits() {
    await this.router.navigate(['units'], {
      queryParamsHandling: 'preserve',
      relativeTo: this.route
    });
  }
}

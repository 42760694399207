<section class="unit-images">
  <app-gallery-popup
    [isHub]="false"
    [images]="getUnitImages(unit.reservationUnitImageList)"
    [name]="unit.name"
  >
    <p-galleria
      class="unit-gallery"
      [value]="unit.reservationUnitImageList"
      [showThumbnails]="false"
      [showIndicators]="true"
      [showIndicatorsOnItem]="true"
      [showItemNavigators]="true"
      [showItemNavigatorsOnHover]="true"
    >
      <ng-template pTemplate="item" let-item>
        <div class="img-wrapper">
          <img [src]="baseImageUrl + item.imagePath" [alt]="unit.name" />
        </div>
      </ng-template>
    </p-galleria>
  </app-gallery-popup>
</section>

<ng-container>
  <section class="booking-wrapper">
    <article class="booking-hub" *ngIf="booking.hub">
      <h2>{{ booking.hub.name }}</h2>
      <p class="address">
        <span *ngIf="booking.hub.neighbourhood">{{ booking.hub.neighbourhood + ', ' }}</span>
        <span>{{ booking.hub.city }}</span>
      </p>

      <div class="hub-perks color-gray">
        <ng-container *ngFor="let perk of booking.hub.perks">
          <span class="material-symbols-outlined">{{ perk.icon }}</span>
          <span class="perk-name">{{ perk.name }}</span>
        </ng-container>
      </div>
    </article>

    <article class="booking-summary">
      <h3>Booking summary</h3>

      <div class="checkin">
        <h5>Check-in</h5>
        <h5>Check-out</h5>
        <h3>{{ dayjs(booking.startDate).format('dddd, DD MMM YYYY') }}</h3>
        <h3>{{ dayjs(booking.endDate).format('dddd, DD MMM YYYY') }}</h3>
        <span class="color-gray">{{ booking.hub?.checkin }}</span>
        <span class="color-gray">{{ booking.hub?.checkout }}</span>
      </div>

      <div class="duration">
        <h5>Trip length:</h5>
        <p>{{ booking.nights + ' nights' }}</p>
      </div>

      <p-divider />

      <h5>
        <span>{{
              'Your selection of ' + booking.units?.length +
              (booking.units?.length === 1 ? ' unit' : ' units')
        }}</span>
        <span>{{
            ' for ' + booking.adults +
            (booking.adults === '1' ? ' adult' : ' adults')
        }}</span>
        <span *ngIf="booking.children">{{
            ' and ' + booking.children +
            (booking.children === '1' ? ' child' : ' children')
        }}</span>
      </h5>

      <p-accordion
        *ngIf="booking.units?.length"
        [multiple]="true"
        [activeIndex]="booking.units?.length === 1 ? [0] : null"
      >
        <p-accordionTab *ngFor="let unit of booking.units">
          <ng-template pTemplate="header">
            <div class="tab-header">
              <span>{{ unit.name }}</span>
              <span
                >{{ (booking.nights * unit.price) + (unit | computed: calculateFeaturesTotal) + ' lei' }}</span
              >
            </div>
          </ng-template>

          <app-price-breakdown [unitId]="unit?.id" isCheckout />
        </p-accordionTab>
      </p-accordion>
    </article>

    <article class="booking-payment">
      <h3>Payment summary</h3>

      <div class="total">
        <span>Total:</span>
        <div>
          <span>{{ (booking | computed: calculateBookingTotal) + ' lei' }}</span>
        </div>
      </div>

      <div class="price-info">
        <span class="material-symbols-outlined  color-gray">payments</span>
        <span class="color-gray"
          >The price includes 9% VAT and all other property related taxes.</span
        >
      </div>
    </article>
  </section>
</ng-container>

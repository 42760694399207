<section class="shell-wrapper">
  <header class="main-header">
    <div class="main-header-section">
      <div class="logo" routerLink="/">hubix</div>
    </div>
    <p-toolbar
      class="main-header-section"
      *ngIf="(isSearchBarVisible$ | async) && cities.length"
      @fadeInOut
    >
      <app-search-bar
        [searchTerms]="searchTerms"
        [cities]="cities"
        (hubSearch)="handleHubSearch($event)"
      />
    </p-toolbar>
    <div class="main-header-section"></div>
  </header>

  <main
    class="main-wrapper"
    [@routeAnimate]="viewChildOutlet && viewChildOutlet.activatedRouteData && viewChildOutlet.activatedRouteData['navIndex']"
  >
    <router-outlet />
  </main>

  <footer class="main-footer"></footer>
</section>

<div class="overlay" @fadeInOut *ngIf="fullPageLoading">
  <div class="overlay-content">
    <p-progressSpinner strokeWidth="4" styleClass="fullpage-spinner"></p-progressSpinner>
  </div>
</div>

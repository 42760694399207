<form class="hub-calendar" [formGroup]="calendarForm">
  <p-calendar
    formControlName="rangeDates"
    [inline]="true"
    [showButtonBar]="true"
    [showOtherMonths]="false"
    (onMonthChange)="triggerCalendarMonthChange($event)"
    selectionMode="range"
  >
    <ng-template pTemplate="header">
      <p-button> Guests Btn</p-button>
    </ng-template>

    <ng-template pTemplate="date" let-date>
      <div
        class="hub-calendar-day"
        [ngClass]="{ date, intervalStartDate, intervalEndDate } | computed: isHighlighted "
      >
        <span>{{ date.day }}</span>
      </div>
      <div class="guests">
        <span class="material-symbols-rounded"> supervisor_account</span>
        <span
          *ngIf="({ date, availableDates } | computed: getDayCapacity) as dayCapacity"
          >{{ dayCapacity }}</span
        >
      </div>
    </ng-template>
  </p-calendar>
  <div class="calendar-spinner-container" *ngIf="isCalendarLoading">
    <div class="calendar-spinner">
      <p-progressSpinner strokeWidth="3"></p-progressSpinner>
    </div>
  </div>
</form>

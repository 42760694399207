<section class="gallery-container">
  <div class="images-wrapper" (click)="isHub ? showDialog() : ''" [ngClass]="{'isHub' : isHub}">
    <ng-content />

    <p-button
      class="btn-open"
      icon="pi pi-images"
      size="small"
      severity="secondary"
      [outlined]="true"
      [label]="isHub ? 'Show all photos' : ''"
      (click)="!isHub ? showDialog() : ''"
    />
  </div>

  <p-dialog
    [(visible)]="visible"
    [modal]="true"
    [draggable]="false"
    [resizable]="false"
    [dismissableMask]="true"
    [closeIcon]="'pi pi-chevron-left'"
  >
    <div class="image-grid" *ngIf="images">
      <div class="inner" *ngFor="let item of images; let index = index">
        <img
          [src]="baseImageUrl + item.path"
          [alt]="'Image ' + index + ' for ' + name"
          (click)="imageClick(index)"
        />
      </div>
    </div>

    <p-galleria
      class="fullscreen-gallery"
      [value]="images"
      [(visible)]="displayCustom"
      [(activeIndex)]="activeIndex"
      [circular]="true"
      [fullScreen]="true"
      [showItemNavigators]="true"
      [showThumbnails]="false"
    >
      <ng-template pTemplate="item" let-item>
        <div class="fullscreenImgBox">
          <img [ngSrc]="baseImageUrl + item.path" [alt]="name" priority fill />
        </div>
      </ng-template>
    </p-galleria>
  </p-dialog>
</section>

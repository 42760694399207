import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { inject } from '@angular/core';
import { HubService } from '../../services';
import { map, zip } from 'rxjs';
import dayjs from 'dayjs';
import { API_DATE_FORMAT } from '@shared/';

export const SingleHubResolver: ResolveFn<any> = (route: ActivatedRouteSnapshot) => {
  const { hubId } = route.params;
  const hubService = inject(HubService);
  const hub$ = hubService.getHub(hubId);
  const startDate = dayjs().startOf('month').format(API_DATE_FORMAT);
  const endDate = dayjs().add(1, 'month').endOf('month').format(API_DATE_FORMAT);
  const availableDates$ = hubService.getHubDateAvailability(hubId, {
    startDate,
    endDate
  });
  return zip(hub$, availableDates$).pipe(
    map(([hub, availableDates]) => ({
      hub,
      availableDates
    }))
  );
};

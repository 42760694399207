<article>
  <h2 class="unit-name">
    <span>{{ unit.name }}</span>
    <span class="selected" *ngIf="isUnitSelected" @fadeInOut>
      <span>- SELECTED</span>
      <span class="material-symbols-outlined"> done_outline </span>
    </span>
  </h2>

  <div class="inline-icon-item unit-location" *ngIf="unit.street">
    <span class="material-symbols-outlined">home_pin</span>
    <span>{{ unit.street }}</span>
    <span *ngIf="unit.streetNo">{{ ' ' + unit.streetNo }}</span>
    <span *ngIf="unit.building">{{ ', ' + unit.building }}</span>
  </div>

  <h4>Guest capacity:</h4>
  <div class="unit-guests">
    <div class="inline-icon-item">
      <span class="material-symbols-rounded">face</span>
      <span>adults:</span>
      <span class="bold">{{ unit.maxAdultsCount }}</span>
    </div>
    <div class="inline-icon-item">
      <span class="material-symbols-rounded">child_care</span>
      <span>children:</span>
      <span class="bold">{{ unit.maxChildrenCount }}</span>
    </div>
  </div>

  <h4>Specs:</h4>
  <div class="unit-specs">
    <div class="inline-icon-item">
      <span class="material-symbols-outlined"> square_foot </span>
      <span>space:</span>
      <span class="bold">{{ unit.area }} m<sup>2</sup></span>
    </div>
    <div class="inline-icon-item">
      <span class="material-symbols-outlined"> floor </span>
      <span>floor:</span>
      <span class="bold">{{ unit.floor }}</span>
    </div>
    <div class="inline-icon-item" *ngIf="unit.isViewDisplayed">
      <span class="material-symbols-outlined"> explore </span>
      <span>facing:</span>
      <span class="bold">{{ unit.view.code | extractView }}</span>
    </div>
  </div>

  <section class="unit-amenities" *ngIf="unit.amenities.length">
    <p-divider>This unit offers:</p-divider>
    <ng-container *ngFor="let amenity of unit.amenities; let i=index">
      <div class="inline-icon-item" *ngIf="i < 6">
        <span
          class="material-symbols-outlined"
          >{{ amenity.icon ? amenity.icon : 'add_diamond' }}</span
        >
        <span>{{ amenity.name }}</span>
      </div>
    </ng-container>
    <p-button
      *ngIf="unit.amenities.length > 5"
      [label]="'Show all ' + unit.amenities.length + ' amenities'"
      severity="secondary"
      [outlined]="true"
      (click)="showPerksDialog()"
    />

    <p-dialog
      header="Amenities"
      [(visible)]="isPerksDialogVisible"
      [modal]="true"
      [draggable]="false"
      [resizable]="false"
      [dismissableMask]="true"
    >
      <div class="inline-icon-item dialog" *ngFor="let amenity of unit.amenities">
        <span
          class="material-symbols-outlined"
          >{{ amenity.icon ? amenity.icon : 'add_diamond' }}</span
        >
        <span>{{ amenity.name }}</span>
      </div>
    </p-dialog>
  </section>

  <p-accordion *ngIf="unit.extraFeatures.length" styleClass="outer-accordion">
    <p-accordionTab>
      <ng-template pTemplate="header">
        <span>Extra Features</span>
        <div>
          <span class="selected" *ngIf="selectedExtras.length" @fadeInOut>
            <span>{{ selectedExtras.length + ' SELECTED' }}</span>
            <span class="material-symbols-outlined"> done_outline </span>
          </span>
        </div>
      </ng-template>

      <form [formGroup]="extrasForm">
        <div class="unit-extra" *ngFor="let extra of unit.extraFeatures">
          <p-accordion
            styleClass="inner-accordion"
            expandIcon="pi pi-plus"
            collapseIcon="pi pi-minus"
          >
            <p-accordionTab>
              <ng-template pTemplate="header">
                <div class="extra-wrapper">
                  <div class="extra-icon">
                    <span
                      class="material-symbols-outlined"
                      >{{ extra.icon ? extra.icon : 'add_diamond' }}</span
                    >
                  </div>
                  <div class="extra-info">
                    <div class="extra-name">
                      <span>{{ extra.name + ' - ' + extra.price + ' lei' }}</span>
                    </div>
                    <div class="extra-desc">
                      <span
                        >{{ extra.description ? extra.description : 'Extra feature description' }}</span
                      >
                    </div>
                  </div>
                  <p-badge
                    *ngIf="isExtraSelected(extra.id)"
                    [value]="extrasForm.get('date_' + extra.id)!.value.length.toString()"
                  />
                </div>
              </ng-template>

              <div class="extra-dates">
                <p><label [for]="extra.id">Choose your dates:</label></p>
                <div>
                  <p-calendar
                    [formControlName]="'date_' + extra.id"
                    [inputId]="extra.id"
                    selectionMode="multiple"
                    [readonlyInput]="true"
                    [minDate]="minDate"
                    [maxDate]="maxDate"
                    [iconDisplay]="'input'"
                    [showIcon]="true"
                    [dateFormat]="'dd.mm'"
                  />
                  <p-button
                    *ngIf="extrasForm.get('date_' + extra.id)!.value && !isExtraSelected(extra.id)"
                    icon="pi pi-check"
                    (click)="triggerExtrasChangeSelection(extra.id, 'add')"
                  />
                  <p-button
                    *ngIf="extrasForm.get('date_' + extra.id)!.value && isExtraSelected(extra.id)"
                    icon="pi pi-times"
                    severity="danger"
                    (click)="triggerExtrasChangeSelection(extra.id, 'remove')"
                  />
                </div>
              </div>
            </p-accordionTab>
          </p-accordion>
        </div>
      </form>
    </p-accordionTab>
  </p-accordion>
</article>

<form [formGroup]="searchForm">
  <div class="input-group">
    <span class="input-group-icon material-symbols-outlined"> location_city </span>
    <p-autoComplete
      formControlName="city"
      [suggestions]="filteredCities"
      [forceSelection]="true"
      (completeMethod)="filterCities($event)"
      [showClear]="true"
      [showEmptyMessage]="true"
      [completeOnFocus]="true"
      inputStyleClass="search-input"
      scrollHeight="300px"
      placeholder="Destination"
    >
      <ng-template pTemplate="empty">
        <span>There are no available destinations for your search</span>
      </ng-template>
    </p-autoComplete>
  </div>

  <div class="input-group">
    <span class="input-group-icon material-symbols-outlined"> calendar_month </span>
    <p-calendar
      name="calendar"
      formControlName="rangeDates"
      selectionMode="range"
      [minDate]="today"
      [showClear]="true"
      [readonlyInput]="true"
      [dateFormat]="dateDisplayFormat"
      [inputStyleClass]="(dateDisplayFormat === 'dd.mm') ? 'search-input' : 'search-input-extended'"
      placeholder="Period"
    >
    </p-calendar>
  </div>

  <div class="input-group">
    <span class="input-group-icon material-symbols-outlined"> group </span>
    <div class="p-inputtext" (click)="op.toggle($event)">
      <div *ngIf="adultCount || childCount; else personsSelected">
        <span *ngIf="adultCount">
          <span>Adults:</span>
          <span class="highlighted">{{ adultCount }}</span>
        </span>
        <span *ngIf="childCount">
          <span>Children:</span>
          <span class="highlighted">{{ childCount }}</span>
        </span>
      </div>
      <ng-template #personsSelected>
        <span>Add guests</span>
      </ng-template>
    </div>
    <p-overlayPanel #op>
      <ng-template pTemplate="content">
        <p-inputNumber
          class="guest-input-wrapper"
          formControlName="adultCount"
          [showButtons]="true"
          [min]="0"
          suffix=" adults"
          buttonLayout="horizontal"
          decrementButtonClass="p-button-secondary"
          incrementButtonClass="p-button-secondary"
          incrementButtonIcon="pi pi-plus"
          decrementButtonIcon="pi pi-minus"
          inputStyleClass="guest-input"
        ></p-inputNumber>

        <p-inputNumber
          class="guest-input-wrapper"
          formControlName="childCount"
          [showButtons]="true"
          [min]="0"
          suffix=" children"
          buttonLayout="horizontal"
          decrementButtonClass="p-button-secondary"
          incrementButtonClass="p-button-secondary"
          incrementButtonIcon="pi pi-plus"
          decrementButtonIcon="pi pi-minus"
          inputStyleClass="guest-input"
        ></p-inputNumber>
      </ng-template>
    </p-overlayPanel>
  </div>

  <div class="input-group">
    <p-button
      class="form-submit"
      label="Search"
      [loading]="isLoading"
      [disabled]="!isValid"
      (onClick)="triggerHubSearch()"
    >
      <span class="material-symbols-outlined"> search </span>
    </p-button>
  </div>
</form>

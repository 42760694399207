import { Pipe, PipeTransform } from '@angular/core';
import { UnitType } from '@shared/';

@Pipe({
  name: 'prettyName',
  standalone: true
})
export class PrettyNamePipe implements PipeTransform {
  transform(type: string, entityType: 'unit'): string {
    if (entityType) {
      switch (type.toUpperCase()) {
        case UnitType['01']:
          return 'single room apartment';
        case UnitType['02']:
          return 'studio';
        case UnitType['03']:
          return 'two rooms apartament';
        case UnitType['04']:
          return 'three rooms apartament';
        case UnitType['05']:
          return 'four rooms apartament';
        case UnitType['06']:
          return 'penthouse';
        case UnitType['07']:
          return 'duplex';
        default:
          return type;
      }
    } else {
      return type;
    }
  }
}

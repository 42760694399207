<section class="hubs-list-group">
  <ng-container *ngFor="let hub of hubs">
    <article class="hub-card">
      <div class="card-image" *ngIf="hub?.images?.length">
        <p-galleria
          [value]="hub.images"
          [showThumbnails]="false"
          [showIndicators]="true"
          [showIndicatorsOnItem]="true"
          [showItemNavigators]="true"
          [showItemNavigatorsOnHover]="true"
        >
          <ng-template pTemplate="item" let-item>
            <div class="img-wrapper" (click)="triggerNavigateToHub(hub.id)">
              <img [ngSrc]="baseImageUrl + item.imagePath" [alt]="hub.name" priority fill />
            </div>
          </ng-template>
        </p-galleria>
      </div>

      <div class="card-content" (click)="triggerNavigateToHub(hub.id)">
        <header class="hub-summary">
          <h2>{{ hub.name }}</h2>
          <p>
            <span class="material-symbols-outlined">home_pin</span>
            <span class="hub-city">Militari, {{ hub.city?.name }}</span>
            <!--            TODO de adaugat cartier, cand va exista informatia-->
          </p>
        </header>

        <div class="hub-capacity">
          <p>
            <span>Total units:</span>
            <p-badge [value]="hub.reservationUnitCount.toString()" />
          </p>

          <p>
            <span>Adults capacity:</span>
            <p-badge [value]="hub.totalAdultsCount.toString()" />
          </p>
          <p>
            <span>Children capacity:</span>
            <p-badge [value]="hub.totalChildrenCount.toString()" />
          </p>
        </div>

        <footer class="hub-perks">
          <ng-container *ngFor="let perk of hub.perks">
            <span
              class="material-symbols-outlined"
              [pTooltip]="tooltipContent"
              tooltipPosition="bottom"
              >{{ getPerkIcon(perk.name) }}</span
            >
            <ng-template #tooltipContent>
              <span>{{ perk.name }}</span>
            </ng-template>
          </ng-container>
        </footer>
      </div>
    </article>
  </ng-container>
</section>

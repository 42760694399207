import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import {
  BookingUnitExtra,
  SearchBarSelectionInternal,
  Unit,
  UnitSelectedExtraFeatures
} from '@shared/';
import dayjs from 'dayjs';
import { ButtonModule } from 'primeng/button';
import { ComputedPipe } from '../../../pipes';
import { StoreService } from '../../../services';
import { PriceBreakdownComponent } from '../../common/price-breakdown/price-breakdown.component';

@Component({
  selector: 'app-unit-price',
  standalone: true,
  imports: [ButtonModule, ComputedPipe, CommonModule, PriceBreakdownComponent],
  templateUrl: './unit-price.component.html',
  styleUrl: './unit-price.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.Default
})
export class UnitPriceComponent implements OnInit {
  @Input() unit!: Unit;
  @Input() selection: SearchBarSelectionInternal | null = null;
  @Input() selectedExtras!: BookingUnitExtra[];
  @Input() isUnitSelected: boolean = false;
  startDate!: any;
  endDate!: any;
  adults: string | null | undefined;
  children: string | null | undefined;

  constructor(private store: StoreService) {}

  ngOnInit() {
    this.startDate = dayjs(this.selection?.startDate).format('DD MMM YYYY');
    this.endDate = dayjs(this.selection?.endDate).format('DD MMM YYYY');
    this.adults = this.selection?.adultCount;
    this.children = this.selection?.childCount;
  }

  public triggerChangeSelection(id: string, action: 'add' | 'remove'): void {
    action === 'add' ? this.store.addUnitToSelection(id) : this.store.removeUnitFromSelection(id);
  }
}

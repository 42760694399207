import { animate, query, sequence, style, transition, trigger } from '@angular/animations';

const initStyle = style({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%'
});

const leaveQuery = query(':leave', [animate('100ms ease-out', style({ opacity: 0 }))], {
  optional: true
});
const enterQuery = query(':enter', [animate('250ms ease-out', style({ left: '0', opacity: 1 }))]);

export const routerAnimation = trigger('routeAnimate', [
  transition(':increment', [
    style({ position: 'relative' }),
    query(':enter, :leave', [initStyle]),
    query(':enter', [style({ left: '50px', opacity: 0 })]),
    sequence([leaveQuery, enterQuery])
  ]),

  transition(':decrement', [
    style({ position: 'relative' }),
    query(':enter, :leave', [initStyle]),
    query(':enter', [style({ left: '-50px', opacity: 0 })]),
    sequence([leaveQuery, enterQuery])
  ])
]);

import { Injectable } from '@angular/core';
import {
  BookingBreakdown,
  BookingPerk,
  BookingUnit,
  BookingUnitExtra,
  City,
  Client,
  Hub,
  HubSummary,
  RoutedPage,
  SearchBarSelectionInternal,
  SearchBarSelectionOutbound,
  Unit,
  UnitSelectedExtraFeatures,
  UnitWithSelectedExtras
} from '@shared/';
import dayjs from 'dayjs';
import cloneDeep from 'lodash/cloneDeep';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  map,
  Observable,
  shareReplay
} from 'rxjs';

export interface AppState {
  currentPage: RoutedPage;
  allHubSummaries: HubSummary[];
  searchSelection: SearchBarSelectionInternal | null;
  selectedHub: Hub | null;
  currentAvailableUnits: Unit[];
  selectedClient: Client | null;
  selectedUnitIds: Array<string>;
  unitSelectedExtraFeatures: UnitSelectedExtraFeatures[];
}

@Injectable({
  providedIn: 'root'
})
export class StoreService {
  constructor() {
    this.appState = {
      currentPage: '' as RoutedPage,
      allHubSummaries: [],
      searchSelection: null,
      selectedHub: null,
      currentAvailableUnits: [],
      selectedClient: null,
      selectedUnitIds: [],
      unitSelectedExtraFeatures: []
    };
    this.appStateSubject = new BehaviorSubject<AppState>(this.appState);
  }

  private appState: AppState;
  private appStateSubject: BehaviorSubject<AppState>;

  private set(changedState: Partial<{ [key in keyof AppState]: any }>): void {
    const newState = cloneDeep(this.appState);
    Object.assign(newState, changedState);

    this.appState = newState;
    this.appStateSubject.next(this.appState);
  }

  public setCurrentPage(currentPage: RoutedPage): void {
    this.set({ ...this.appState, currentPage });
  }

  public setHubSummaries(allHubSummaries: HubSummary[]): void {
    this.set({
      ...this.appState,
      allHubSummaries
    });
  }

  public setSearchSelection(selection: SearchBarSelectionOutbound): void {
    this.set({
      ...this.appState,
      searchSelection: {
        city: selection.city,
        startDate: selection.startDate,
        endDate: selection.endDate,
        adultCount: selection.maxAdultsCount,
        childCount: selection.maxChildrenCount
      }
    });
  }

  public setSelectedHub(selectedHub: Hub): void {
    this.set({
      ...this.appState,
      selectedHub
    });
  }

  public setAvailableUnits(currentAvailableResUnits: Unit[]): void {
    this.set({
      ...this.appState,
      currentAvailableUnits: currentAvailableResUnits
    });
  }

  public addUnitToSelection(id: string): void {
    if (this.appState.selectedUnitIds.find(elem => elem === id)) return;
    const newSelectedUnitIds = [...this.appState.selectedUnitIds, id];
    this.set({
      ...this.appState,
      selectedUnitIds: newSelectedUnitIds
    });
  }

  public removeUnitFromSelection(id: string): void {
    const newSelectedUnitIds = [...this.appState.selectedUnitIds].filter(elem => elem !== id);
    this.set({
      ...this.appState,
      selectedUnitIds: newSelectedUnitIds
    });
    this.removeAllExtraFeaturesFromUnitSelection(id);
  }

  public addExtraFeatureToUnitSelection(
    unitId: string,
    extraFeatureId: string,
    days: string[]
  ): void {
    const selectedUnitExtraFeatureMap = cloneDeep(this.appState.unitSelectedExtraFeatures);
    const currentUnit = selectedUnitExtraFeatureMap.find(item => item.unitId === unitId);

    let newSelectedUnitExtraFeatureMap;

    if (currentUnit) {
      const newUnitExtraFeature = {
        unitId,
        extraFeatures: [
          ...currentUnit.extraFeatures.filter(feature => feature.extraFeatureId !== extraFeatureId),
          { extraFeatureId, days }
        ]
      };
      newSelectedUnitExtraFeatureMap = [
        ...selectedUnitExtraFeatureMap.filter(elem => elem.unitId !== unitId),
        newUnitExtraFeature
      ];
    } else {
      newSelectedUnitExtraFeatureMap = [
        ...selectedUnitExtraFeatureMap,
        { unitId, extraFeatures: [{ extraFeatureId, days }] }
      ];
    }

    this.set({
      ...this.appState,
      unitSelectedExtraFeatures: newSelectedUnitExtraFeatureMap
    });
  }

  public removeExtraFeatureFromUnitSelection(unitId: string, extraFeatureId: string): void {
    const selectedUnitExtraFeatureMap: UnitSelectedExtraFeatures[] = cloneDeep(
      this.appState.unitSelectedExtraFeatures
    );
    const currentUnit = selectedUnitExtraFeatureMap.find(item => item.unitId === unitId);
    const newSelectedUnitExtraFeatureMapUnitRemoved = selectedUnitExtraFeatureMap.filter(
      elem => elem.unitId !== unitId
    );
    let newSelectedUnitExtraFeatureMap: UnitSelectedExtraFeatures[];

    if (currentUnit?.extraFeatures.length === 1) {
      newSelectedUnitExtraFeatureMap = newSelectedUnitExtraFeatureMapUnitRemoved;
    } else {
      const newFeatureMap = currentUnit!.extraFeatures.filter(
        feature => feature.extraFeatureId !== extraFeatureId
      );
      newSelectedUnitExtraFeatureMap = [
        ...newSelectedUnitExtraFeatureMapUnitRemoved,
        { unitId, extraFeatures: newFeatureMap }
      ];
    }

    this.set({
      ...this.appState,
      unitSelectedExtraFeatures: newSelectedUnitExtraFeatureMap
    });
  }

  public removeAllExtraFeaturesFromUnitSelection(unitId: string): void {
    const selectedUnitExtraFeatureMap: UnitSelectedExtraFeatures[] = cloneDeep(
      this.appState.unitSelectedExtraFeatures
    );

    this.set({
      ...this.appState,
      unitSelectedExtraFeatures: selectedUnitExtraFeatureMap.filter(elem => elem.unitId !== unitId)
    });
  }

  get state$(): Observable<AppState> {
    return this.appStateSubject.asObservable().pipe(shareReplay(1), distinctUntilChanged());
  }

  get currentPage$(): Observable<RoutedPage> {
    return this.state$.pipe(
      map(state => state.currentPage),
      distinctUntilChanged()
    );
  }

  get hubSummaries$(): Observable<HubSummary[]> {
    return this.state$.pipe(
      map(state => state.allHubSummaries),
      distinctUntilChanged()
    );
  }

  get availableCities$(): Observable<City[]> {
    return this.hubSummaries$.pipe(
      map(hubSummaries => {
        return hubSummaries.reduce((accum: City[], hub: HubSummary): City[] => {
          return accum.find(elem => elem.id === (hub.city?.id as string))
            ? accum
            : [...accum, hub.city as City];
        }, []);
      }),
      distinctUntilChanged()
    );
  }

  get currentSelection$(): Observable<SearchBarSelectionInternal | null> {
    return this.state$.pipe(
      map(state => state.searchSelection),
      distinctUntilChanged()
    );
  }

  get selectedHub$(): Observable<Hub | null> {
    return this.state$.pipe(
      map(state => state.selectedHub),
      distinctUntilChanged()
    );
  }

  get totalNightsSelection$(): Observable<number> {
    return this.currentSelection$.pipe(
      map(selection => {
        if (!selection) return 0;
        const startDate = selection.startDate;
        const endDate = selection.endDate;
        return dayjs(endDate).diff(dayjs(startDate), 'days');
      }),
      distinctUntilChanged()
    );
  }

  get selectedUnitIds$(): Observable<Array<string>> {
    return this.state$.pipe(
      map(state => state.selectedUnitIds),
      distinctUntilChanged()
    );
  }

  get selectedUnitExtraFeatureMap$(): Observable<UnitSelectedExtraFeatures[]> {
    return this.state$.pipe(
      map(state => state.unitSelectedExtraFeatures),
      distinctUntilChanged()
    );
  }

  // get stepperStage$(): Observable<number> {
  //   return this.state$.pipe(
  //     map(state => state.stepperStage),
  //     distinctUntilChanged()
  //   );
  // }

  get currentAvailableUnits$(): Observable<Unit[]> {
    return this.state$.pipe(
      map(state => state.currentAvailableUnits),
      distinctUntilChanged()
    );
  }

  get isSearchBarVisible$(): Observable<boolean> {
    return this.currentPage$.pipe(
      map(page => page === RoutedPage.HOME),
      distinctUntilChanged()
    );
  }

  get selectedUnits$(): Observable<Unit[]> {
    return combineLatest([this.currentAvailableUnits$, this.selectedUnitIds$]).pipe(
      map(([units, selectedUnitIds]) => units.filter(unit => selectedUnitIds.includes(unit.id))),
      distinctUntilChanged()
    );
  }

  get selectedUnitsWithSelectedExtras$(): Observable<UnitWithSelectedExtras[]> {
    return combineLatest([this.selectedUnits$, this.selectedUnitExtraFeatureMap$]).pipe(
      map(([units, features]) => {
        const newUnits: UnitWithSelectedExtras[] = [];

        units.forEach(unit => {
          const newExtras: BookingUnitExtra[] = [];

          const selectedFeaturesInSelectedUnit = features.find(
            feature => feature.unitId === unit.id
          );
          if (selectedFeaturesInSelectedUnit) {
            selectedFeaturesInSelectedUnit.extraFeatures.forEach(selectedFeature => {
              const equivalentExtraInUnit = unit.extraFeatures.find(
                feature => feature.id === selectedFeature.extraFeatureId
              );
              if (equivalentExtraInUnit) {
                newExtras.push({
                  id: equivalentExtraInUnit.id,
                  name: equivalentExtraInUnit.name,
                  price: equivalentExtraInUnit.price,
                  days: [...selectedFeature.days]
                });
              }
            });
          }

          newUnits.push({ ...unit, extraFeatures: newExtras });
        });

        return newUnits;
      }),
      distinctUntilChanged()
    );
  }

  get bookingBreakdown$(): Observable<BookingBreakdown> {
    return combineLatest([
      this.selectedHub$,
      this.currentSelection$,
      this.totalNightsSelection$,
      this.selectedUnitsWithSelectedExtras$
    ]).pipe(
      map(([hub, selection, nights, units]) => {
        if (!hub || !selection || !nights || !units) {
          return {
            adults: null,
            children: null,
            nights: 0,
            startDate: null,
            endDate: null,
            hub: null,
            units: null
          };
        }

        const checkoutPerks: BookingPerk[] = [];
        hub.perks.forEach(perk => checkoutPerks.push({ name: perk.name, icon: perk.icon }));

        const checkoutUnits: BookingUnit[] = [];
        units.forEach(unit =>
          checkoutUnits.push({
            id: unit.id,
            name: unit.name,
            price: unit.averagePrice,
            extras: unit.extraFeatures,
            street: unit.street,
            streetNo: unit.streetNo,
            building: unit.building
          })
        );

        return {
          adults: selection.adultCount,
          children: selection.childCount,
          nights,
          startDate: selection.startDate,
          endDate: selection.endDate,
          hub: {
            id: hub.id,
            name: hub.name,
            neighbourhood: hub.neighbourhood,
            city: hub.city!.name,
            checkin: hub.checkin,
            checkout: hub.checkout,
            perks: [...checkoutPerks]
          },
          units: [...checkoutUnits]
        };
      }),
      distinctUntilChanged()
    );
  }

  get bookingTotal$(): Observable<number> {
    return this.bookingBreakdown$.pipe(
      map(booking => {
        if (!booking || !booking.nights || !booking.units) return 0;

        function calculateFeaturesTotal(unit: BookingUnit): number {
          if (!unit || !unit.extras) return 0;
          return unit?.extras?.reduce((accum, elem) => {
            if (!elem.days?.length) return 0;
            return accum + elem.price * elem.days.length;
          }, 0);
        }

        return booking.units.reduce((accum, elem) => {
          return accum + booking.nights * elem.price + calculateFeaturesTotal(elem);
        }, 0);
      }),
      distinctUntilChanged()
    );
  }
}

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { API_DATE_FORMAT, AutoUnsubscribe, Hub, HubDateAvailabilityMap, Unit } from '@shared/';
import { HubService, StoreService } from '../../services';
import { HubDetailsComponent } from './details/hub-details.component';
import { HubImagesComponent } from './images/hub-images.component';
import { BehaviorSubject, map } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { HubCalendarComponent } from './calendar/hub-calendar.component';
import { CalendarMonthChangeEvent } from 'primeng/calendar/calendar.interface';
import dayjs from 'dayjs';

@Component({
  selector: 'app-hub',
  standalone: true,
  imports: [HubDetailsComponent, HubImagesComponent, CommonModule, HubCalendarComponent],
  templateUrl: './hub.component.html',
  styleUrl: './hub.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
@AutoUnsubscribe
export class HubComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private store: StoreService,
    private hubService: HubService
  ) {}

  hub!: Hub | null;
  availableUnits: Unit[] = [];
  hasSelection: boolean = false;
  isCalendarLoading: boolean = false;
  availableDatesSubject$: BehaviorSubject<HubDateAvailabilityMap | null> =
    new BehaviorSubject<HubDateAvailabilityMap | null>(null);

  ngOnInit() {
    this.route.data
      .pipe(map(data => data['reqData']))
      .subscribe(({ hub, availableUnits, availableDates }) => {
        this.hub = hub;
        this.availableUnits = availableUnits;
        if (availableDates) this.availableDatesSubject$.next(availableDates);
      });
    this.store.currentSelection$.subscribe(selection => (this.hasSelection = !!selection));
  }

  handleMonthChange({ month, year }: CalendarMonthChangeEvent) {
    this.isCalendarLoading = true;
    const startDate = dayjs(`${year}-${month}-01`).format(API_DATE_FORMAT);
    const endDate = dayjs(`${year}-${month! + 1}-01`).format(API_DATE_FORMAT);
    this.hubService
      .getHubDateAvailability(this.hub!.id, { startDate, endDate })
      .subscribe(availableDates => {
        this.isCalendarLoading = false;
        this.availableDatesSubject$.next(availableDates);
      });
  }
}

<ng-container *ngIf="unitSubject$ | async as unit">
  <ng-container *ngIf="nightsSubject$ | async as nights">
    <ng-container *ngIf="selectionSubject$ | async as selection">
      <div class="booking-costs" (click)="openPriceBreakdown()">
        <div class="booking-price-line">
          <span
            >{{ unit?.averagePrice + ' lei x ' + nights }}
            {{ nights === 1 ? ' night' : ' nights' }}
          </span>
          <span>{{ unit?.averagePrice! * nights + ' lei' }}</span>
        </div>

        <div class="booking-price-line" *ngIf="unit?.extraFeatures?.length">
          <span>Extra features </span>
          <span>{{ (unit | computed: calculateFeaturesTotal) + ' lei' }}</span>
        </div>

        <ng-container *ngIf="!isCheckout">
          <p-divider />
          <div class="booking-price-line booking-total">
            <span>Total</span>
            <span
              >{{ (unit.averagePrice * nights) + (unit | computed: calculateFeaturesTotal) + ' lei' }}</span
            >
          </div>
        </ng-container>
      </div>

      <p-dialog
        header="Price Breakdown"
        [(visible)]="isPriceBreakdownVisible"
        [modal]="true"
        [draggable]="false"
        [resizable]="false"
        [dismissableMask]="true"
      >
        <div
          class="breakdown-row"
          *ngFor="let day of getBookingDates(selection.startDate, nights); let i = index"
        >
          <span>{{ 'Night ' + (i + 1) }}</span>
          <span>{{ dayjs(day).format('DD MMM YYYY') }}</span>
          <span>{{ unit?.averagePrice + ' lei' }}</span>
        </div>

        <ng-container *ngIf="unit?.extraFeatures?.length">
          <ng-container *ngFor="let selectedExtra of unit?.extraFeatures">
            <ng-container *ngFor="let day of selectedExtra.days">
              <div class="breakdown-row">
                <span>{{ selectedExtra.name }}</span>
                <span>{{ dayjs(day).format('DD MMM YYYY') }}</span>
                <span>{{ selectedExtra.price + ' lei' }}</span>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>

        <p-divider />
        <div class="breakdown-row breakdown-total">
          <span>Total</span>
          <span
            >{{ (unit.averagePrice * nights) + (unit | computed: calculateFeaturesTotal) + ' lei' }}</span
          >
        </div>
      </p-dialog>
    </ng-container>
  </ng-container>
</ng-container>

import { NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { environment } from '@env/';
import { GalleryImage, Unit, UnitImage } from '@shared/*';
import { SharedModule } from 'primeng/api';
import { GalleriaModule } from 'primeng/galleria';
import { GalleryPopupComponent } from '../../common/gallery-popup/gallery-popup.component';

@Component({
  selector: 'app-unit-images',
  standalone: true,
  imports: [GalleriaModule, GalleryPopupComponent, NgOptimizedImage, SharedModule],
  templateUrl: './unit-images.component.html',
  styleUrl: './unit-images.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UnitImagesComponent {
  @Input() unit!: Unit;
  baseImageUrl: string = environment.baseImageUrl;

  getUnitImages(images: UnitImage[]) {
    return images.reduce(
      (accum: GalleryImage[], elem: UnitImage) => [...accum, { id: elem.id, path: elem.imagePath }],
      []
    );
  }
}

<ng-container *ngIf="hub">
  <app-hub-details [hub]="hub" [hasSelection]="hasSelection" [availableUnits]="availableUnits">
    <app-hub-images [hub]="hub" />
    <app-hub-calendar
      hub-calendar
      *ngIf="availableDatesSubject$ | async as availableDates"
      [availableDates]="availableDates"
      [isCalendarLoading]="isCalendarLoading"
      (monthChange)="handleMonthChange($event)"
    />
  </app-hub-details>
</ng-container>

export function attachHubPerkList(hub: any): any {
  return {
    ...hub,
    hubPerkList: [
      {
        id: '8c75c8b1-c320-40c1-b3be-3599270a753b',
        createdAt: 1710853635343,
        updatedAt: null,
        name: 'Metrou',
        hubId: 'b35ee60b-69b5-40d0-be47-ca190d4f1ad9',
        measurement_unit: 'minute',
        measurement_value: '3',
        description: 'Distanța față de cea mai apropiată gură de metrou'
      },
      {
        id: '04a6a457-2799-4ec0-909c-efd1a3e5a64a',
        createdAt: 1710853635343,
        updatedAt: null,
        name: 'Parc',
        hubId: 'b35ee60b-69b5-40d0-be47-ca190d4f1ad9',
        measurement_unit: 'minute',
        measurement_value: '5',
        description: 'Distanța față de cel mai apropiat parc'
      },
      {
        id: '04a6a457-2799-4ec0-909c-efd1a3e5a642',
        createdAt: 1710853635343,
        updatedAt: null,
        name: 'Restaurant',
        hubId: 'b35ee60b-69b5-40d0-be47-ca190d4f1ad9',
        measurement_unit: null,
        measurement_value: '5',
        description: 'Numărul de restaurante în imediată apropiere'
      },
      {
        id: '04a6a457-2799-4ec0-909c-efd1a3e5a643',
        createdAt: 1710853635343,
        updatedAt: null,
        name: 'Centru',
        hubId: 'b35ee60b-69b5-40d0-be47-ca190d4f1ad9',
        measurement_unit: 'minute',
        measurement_value: '4',
        description: 'Acces rapid către centrul orașului'
      },
      {
        id: '04a6a457-2799-4ec0-909c-efd1a3e5a645',
        createdAt: 1710853635343,
        updatedAt: null,
        name: 'Lac',
        hubId: 'b35ee60b-69b5-40d0-be47-ca190d4f1ad9',
        measurement_unit: 'minute',
        measurement_value: '12',
        description: 'Distanța față de cel mai apropiat lac'
      },
      {
        id: '04a6a457-2799-4ec0-909c-efd1a3e5a641',
        createdAt: 1710853635343,
        updatedAt: null,
        name: 'Sală Fitness',
        hubId: 'b35ee60b-69b5-40d0-be47-ca190d4f1ad9',
        measurement_unit: 'minut',
        measurement_value: '1',
        description: 'Distanța față de cea mai apropiată sală de fitness'
      },
      {
        id: '04a6a457-2799-4ec0-909c-efd1a3e5a644',
        createdAt: 1710853635343,
        updatedAt: null,
        name: 'Pădure',
        hubId: 'b35ee60b-69b5-40d0-be47-ca190d4f1ad9',
        measurement_unit: 'minute',
        measurement_value: '20',
        description: 'Distanța față de cea mai apropiată pădure'
      }
    ]
  };
}

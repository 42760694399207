<div class="hub-header">
  <div class="hub-title">
    <h1>{{ hub.name }}</h1>
    <p class="hub-location">
      <span class="material-symbols-outlined">home_pin</span>
      <span *ngIf="hub.neighbourhood">{{ hub.neighbourhood + ', ' }}</span>
      <span>{{ hub.city?.name }}</span>
      <!--      TODO aici tre sa vina city ca string simplu-->
    </p>
  </div>
  <div class="hub-total-units">
    <span class="material-symbols-outlined"> apartment </span>
    <span *ngIf="!hasSelection">{{ hub.reservationUnitList.length }} total units</span>
    <span *ngIf="hasSelection"
      >{{ availableUnits.length }} out of {{ hub.reservationUnitList.length }} units available</span
    >
  </div>
</div>

<ng-content />

<section class="hub-content">
  <div class="left">
    <article class="hub-availability">
      <h3 *ngIf="hasSelection">Available units for your current selection</h3>
      <h3 *ngIf="!hasSelection">Total units in this hub</h3>

      <ul class="hub-units">
        <li
          *ngFor="let unitTypeCountMap of (hasSelection? availableUnits : hub.reservationUnitList) | extractUnitsType | keyvalue"
        >
          <span class="strong">{{ unitTypeCountMap.value?.length }}</span>
          <span> x {{ unitTypeCountMap.key | prettyName: 'unit' | lowercase }}</span>
        </li>
      </ul>

      <div *ngIf="hasSelection">
        <p-button label="Choose Units" (click)="navigateToUnits()">
          <span class="material-symbols-outlined"> navigate_next </span>
        </p-button>
      </div>
    </article>

    <article class="hub-perks" *ngIf="hub.perks.length">
      <p-divider>Perks</p-divider>

      <ng-container *ngFor="let perk of hub.perks; let i = index">
        <div class="perk-item" *ngIf="i < 3">
          <span class="material-symbols-outlined">{{ perk.icon ? perk.icon : 'add_diamond' }}</span>
          <div>
            <p class="perk-name">
              {{ perk.name + ': ' + perk.measurement_value + ' ' + perk.measurement_unit }}
            </p>
            <p
              class="perk-desc"
              >{{ perk.description ? perk.description : 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.' }}</p
            >
          </div>
        </div>
      </ng-container>

      <p-button
        *ngIf="hub.perks.length > 3"
        [label]="'Show all ' + hub.perks.length + ' perks'"
        severity="secondary"
        [outlined]="true"
        (click)="showPerksDialog()"
      />

      <p-dialog
        header="Perks"
        [(visible)]="perksVisible"
        [modal]="true"
        [draggable]="false"
        [resizable]="false"
        [dismissableMask]="true"
      >
        <div class="perk-item" *ngFor="let perk of hub.perks">
          <span class="material-symbols-outlined">{{ perk.icon ? perk.icon : 'add_diamond' }}</span>
          <div>
            <p class="perk-name">
              {{ perk.name + ': ' + perk.measurement_value + ' ' + perk.measurement_unit }}
            </p>
            <p
              class="perk-desc"
              >{{ perk.description ? perk.description : 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.' }}</p
            >
          </div>
        </div>
      </p-dialog>
    </article>

    <article class="hub-description" *ngIf="hub.description">
      <p-divider>Details</p-divider>
      <p>{{ getDisplayedDescription(hub.description) }}</p>

      <p-button
        *ngIf="isDescriptionLong"
        label="Show more"
        [link]="true"
        icon="pi pi-chevron-right"
        iconPos="right"
        (click)="showDescDialog()"
      />

      <p-dialog
        header="About this hub"
        [(visible)]="descVisible"
        [modal]="true"
        [draggable]="false"
        [resizable]="false"
        [dismissableMask]="true"
      >
        <p>{{ hub.description }}</p>
      </p-dialog>
    </article>
  </div>

  <div class="right">
    <article class="hub-calendar">
      <ng-content select="[hub-calendar]"></ng-content>
    </article>
  </div>
</section>

<section class="hub-map">
  <app-map [hubs]="[]" />
  <!--    TODO make map dynamic with pins for all existing or available hub units-->
</section>

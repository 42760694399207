import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { environment } from '@env/';
import { GalleryImage } from '@shared/*';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { GalleriaModule } from 'primeng/galleria';

@Component({
  selector: 'app-gallery-popup',
  standalone: true,
  imports: [DialogModule, CommonModule, ButtonModule, GalleriaModule, NgOptimizedImage],
  templateUrl: './gallery-popup.component.html',
  styleUrl: './gallery-popup.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GalleryPopupComponent {
  @Input() images: GalleryImage[] = [];
  @Input() name: string = '';
  @Input() isHub: boolean = true;

  visible: boolean = false;
  baseImageUrl: string = environment.baseImageUrl;
  displayCustom: boolean = false;
  activeIndex: number = 0;

  showDialog() {
    this.visible = !this.visible;
  }

  imageClick(index: number) {
    this.activeIndex = index;
    this.displayCustom = true;
  }
}

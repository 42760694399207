import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { BookingBreakdown } from '@shared/*';
import { BehaviorSubject } from 'rxjs';
import { StoreService } from '../../services';
import { BookingSummaryComponent } from './booking-summary/booking-summary.component';
import { UserFormComponent } from './user-form/user-form.component';

@Component({
  selector: 'app-checkout',
  standalone: true,
  imports: [BookingSummaryComponent, UserFormComponent, AsyncPipe, NgIf],
  templateUrl: './checkout.component.html',
  styleUrl: './checkout.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckoutComponent implements OnInit {
  bookingSubject$: BehaviorSubject<BookingBreakdown | null> =
    new BehaviorSubject<BookingBreakdown | null>(null);

  constructor(private store: StoreService) {}

  ngOnInit() {
    this.store.bookingBreakdown$.subscribe(breakdown => this.bookingSubject$.next(breakdown));
  }
}

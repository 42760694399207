import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { BookingBreakdown, BookingUnit } from '@shared/*';
import dayjs from 'dayjs';
import { AccordionModule } from 'primeng/accordion';
import { DividerModule } from 'primeng/divider';
import { ComputedPipe } from '../../../pipes';
import { PriceBreakdownComponent } from '../../common/price-breakdown/price-breakdown.component';

function calculateFeaturesTotal(unit: BookingUnit): number {
  if (!unit || !unit.extras) return 0;
  return unit?.extras?.reduce((accum, elem) => {
    if (!elem.days?.length) return 0;
    return accum + elem.price * elem.days.length;
  }, 0);
}

function calculateBookingTotal(booking: BookingBreakdown): number {
  if (!booking || !booking.nights || !booking.units) return 0;
  return booking.units.reduce((accum, elem) => {
    return accum + booking.nights * elem.price + calculateFeaturesTotal(elem);
  }, 0);
}

@Component({
  selector: 'app-checkout-booking-summary',
  standalone: true,
  imports: [CommonModule, DividerModule, AccordionModule, PriceBreakdownComponent, ComputedPipe],
  templateUrl: './booking-summary.component.html',
  styleUrl: './booking-summary.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BookingSummaryComponent {
  @Input() booking!: BookingBreakdown;
  protected readonly calculateFeaturesTotal = calculateFeaturesTotal;
  protected readonly calculateBookingTotal = calculateBookingTotal;
  protected readonly dayjs = dayjs;
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, catchError, of } from 'rxjs';
import { environment } from '@env/';

@Injectable({
  providedIn: 'root'
})
export class RestService {
  constructor(private http: HttpClient) {}

  readonly baseHttpHeaders: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  });

  readonly API_URL = '/api';

  public get(endpoint: string, queryParams?: { [k: string]: string | string[] }): Observable<any> {
    return this.http
      .get(`${this.API_URL}${endpoint}`, {
        headers: this.baseHttpHeaders,
        params: queryParams
      })
      .pipe(
        catchError(err => {
          if (!environment.production) console.log(err);
          return of(err);
        })
      );
  }

  public post(
    endpoint: string,
    payload: any,
    queryParams?: { [k: string]: string | string[] }
  ): Observable<any> {
    return this.http
      .post(`${this.API_URL}${endpoint}`, payload, {
        headers: this.baseHttpHeaders,
        params: queryParams
      })
      .pipe(
        catchError(err => {
          if (!environment.production) console.log(err);
          return of(err);
        })
      );
  }
}

import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { map, Observable, tap } from 'rxjs';
import {
  attachHubPerkList,
  City,
  Hub,
  HubDateAvailabilityMap,
  HubSummary,
  SearchBarSelectionOutbound,
  Unit
} from '@shared/';
import { StoreService } from './store.service';

@Injectable({
  providedIn: 'root'
})
export class HubService {
  constructor(
    private restService: RestService,
    private store: StoreService
  ) {}
  private readonly HUBS_URL = '/public/hubs';
  private readonly CITIES_URL = '/public/cities';
  private readonly AVAILABILITY_URL = '/availability';
  private readonly DATE_AVAILABILITY_URL = '/date-availability';
  private readonly UNITS_URL = '/reservation-units';

  public getAllHubSummaries(): Observable<HubSummary[]> {
    return this.restService.get(`${this.HUBS_URL}/all`).pipe(
      map(res => res || []),
      tap(hubSummaries => this.store.setHubSummaries(hubSummaries))
    );
  }

  public getAllCities(): Observable<City[]> {
    return this.restService.get(`${this.CITIES_URL}/all`).pipe(
      map(res => res || []),
      map((cities: City[]) => {
        const sortedCities = [...cities];
        sortedCities.sort((prev: City, next: City) => (prev.name < next.name ? -1 : 1));
        return sortedCities;
      })
    );
  }

  public getAvailableHubs(searchParams: SearchBarSelectionOutbound): Observable<HubSummary[]> {
    return this.restService
      .get(`${this.HUBS_URL}${this.AVAILABILITY_URL}`, searchParams)
      .pipe(map(res => res || []));
  }

  // todo remove hardcoding of perks for getHub

  public getHub(hubId: string): Observable<Hub> {
    return this.restService.get(`${this.HUBS_URL}/${hubId}`).pipe(
      map(res => res || null),
      map(hub => attachHubPerkList(hub)),
      tap(res => this.store.setSelectedHub(res))
    );
  }

  public getHubDateAvailability(
    hubId: string,
    dateRange: { startDate: string; endDate: string }
  ): Observable<HubDateAvailabilityMap> {
    return this.restService
      .get(`${this.HUBS_URL}/${hubId}${this.DATE_AVAILABILITY_URL}`, dateRange)
      .pipe(map(res => res || null));
  }

  public getAvailableUnits(
    hubId: string,
    dateRange: { startDate: string; endDate: string }
  ): Observable<Unit[]> {
    return this.restService
      .get(`${this.HUBS_URL}/${hubId}${this.AVAILABILITY_URL}${this.UNITS_URL}`, dateRange)
      .pipe(
        map(res => res || []),
        tap(res => this.store.setAvailableUnits(res))
      );
  }
}

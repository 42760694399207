import { Pipe, PipeTransform } from '@angular/core';
import { extractUnitsType, Unit, UnitType } from '@shared/';

@Pipe({
  name: 'extractUnitsType',
  standalone: true
})
export class ExtractUnitsTypePipe implements PipeTransform {
  transform(units: Unit[]): { [key in UnitType]?: Unit[] } {
    return extractUnitsType(units);
  }
}

<app-gallery-popup *ngIf="hub.images.length" [images]="galleryImages" [name]="hub.name">
  <div class="images-grid">
    <div class="grid-item" *ngFor="let image of gridImages; let i = index">
      <img
        [ngSrc]="baseImageUrl + image.imagePath"
        [alt]="'Image ' + (i + 1) + ' of ' + hub.name"
        priority
        fill
      />
    </div>
  </div>
</app-gallery-popup>

import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Params,
  Router,
  RouterLink,
  RouterOutlet
} from '@angular/router';
import {
  City,
  fadeInOutAnimation,
  RouteData,
  routerAnimation,
  SearchBarSelectionOutbound
} from '@shared/';
import { ButtonModule } from 'primeng/button';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ToolbarModule } from 'primeng/toolbar';
import { distinctUntilChanged, filter, map, tap } from 'rxjs';
import { SearchBarComponent } from './components/root/search-bar.component';
import { HubService, StoreService } from './services';

@Component({
  selector: 'app-root',
  animations: [routerAnimation, fadeInOutAnimation],
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    ToolbarModule,
    ProgressSpinnerModule,
    ButtonModule,
    RouterLink,
    SearchBarComponent
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  @ViewChild(RouterOutlet, { static: true }) viewChildOutlet!: RouterOutlet;
  cities: City[] = [];
  searchTerms: SearchBarSelectionOutbound | null = null;
  fullPageLoading: boolean = false;
  isSearchBarVisible$ = this.store.isSearchBarVisible$;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store: StoreService,
    private hubService: HubService
  ) {}

  // todo check for proper functioning of observable chain
  ngOnInit() {
    this.router.events
      .pipe(
        filter(
          event =>
            event instanceof NavigationStart ||
            event instanceof NavigationEnd ||
            event instanceof NavigationCancel ||
            event instanceof NavigationError
        ),
        tap(event => (this.fullPageLoading = event instanceof NavigationStart)),
        filter(event => event instanceof NavigationEnd),
        map(() => {
          let deepestNestedRoute = this.route;
          while (deepestNestedRoute.firstChild) {
            deepestNestedRoute = deepestNestedRoute.firstChild;
          }
          return deepestNestedRoute.snapshot;
        }),
        distinctUntilChanged()
      )
      .subscribe(({ data, queryParams }) => {
        const currentPage = (data as RouteData).page;
        this.store.setCurrentPage(currentPage);
        this.populateSearchSelection(queryParams);
      });

    this.store.availableCities$.subscribe(cities => (this.cities = cities));

    this.hubService.getAllHubSummaries().subscribe();
  }

  public async handleHubSearch({
    city,
    startDate,
    endDate,
    maxAdultsCount,
    maxChildrenCount
  }: SearchBarSelectionOutbound) {
    const queryParams = {
      city,
      startDate,
      endDate,
      maxAdultsCount,
      ...(!!maxChildrenCount && { maxChildrenCount })
    };
    await this.router.navigate([`search/`], { queryParams });
  }

  private populateSearchSelection(queryParams: Params) {
    if (Object.keys(queryParams).length) {
      const { city, startDate, endDate, maxAdultsCount, maxChildrenCount } = queryParams;
      if (city && startDate && endDate && maxAdultsCount) {
        this.searchTerms = {
          city,
          startDate,
          endDate,
          maxAdultsCount,
          ...(!!maxChildrenCount && { maxChildrenCount })
        };
        this.store.setSearchSelection(queryParams as SearchBarSelectionOutbound);
      }
    }
  }
}

import { CommonModule, NgOptimizedImage } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { environment } from '@env/';
import { GalleryImage, Hub, HubImage } from '@shared/*';
import { GalleryPopupComponent } from '../../common/gallery-popup/gallery-popup.component';

@Component({
  selector: 'app-hub-images',
  standalone: true,
  imports: [GalleryPopupComponent, NgOptimizedImage, CommonModule],
  templateUrl: './hub-images.component.html',
  styleUrl: './hub-images.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HubImagesComponent implements OnInit {
  @Input() hub!: Hub;
  baseImageUrl: string = environment.baseImageUrl;
  gridImages: HubImage[] = [];
  galleryImages: GalleryImage[] = [];

  ngOnInit() {
    this.gridImages = this.hub.images.filter((el, index) => index < 5);
    this.galleryImages = this.hub.images.reduce(
      (accum: GalleryImage[], elem) => [...accum, { id: elem.id, path: elem.imagePath }],
      []
    );
  }
}

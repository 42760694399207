import { Route } from '@angular/router';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { HomeComponent } from './components/home/home.component';
import { HubComponent } from './components/hub/hub.component';
import { UnitsComponent } from './components/units/units.component';
import {
  AvailableHubResolver,
  HubsResolver,
  RouteData,
  RoutedPage,
  SearchAvailableHubsResolver,
  SingleHubResolver
} from './shared';

export const routes: Route[] = [
  {
    path: '',
    component: HomeComponent,
    resolve: { reqData: HubsResolver },
    runGuardsAndResolvers: 'always',
    data: <RouteData>{ page: RoutedPage.HOME, navIndex: 1 }
  },
  {
    path: 'search',
    component: HomeComponent,
    resolve: { reqData: SearchAvailableHubsResolver },
    runGuardsAndResolvers: 'always',
    data: <RouteData>{ page: RoutedPage.HOME, navIndex: 1 }
  },
  {
    path: 'hub/:hubId',
    component: HubComponent,
    resolve: { reqData: SingleHubResolver },
    runGuardsAndResolvers: 'always',
    data: <RouteData>{ page: RoutedPage.HUB, navIndex: 2 }
  },
  {
    path: 'available-hub/:hubId',
    component: HubComponent,
    resolve: { reqData: AvailableHubResolver },
    runGuardsAndResolvers: 'always',
    data: <RouteData>{ page: RoutedPage.HUB, navIndex: 2 }
  },
  {
    path: 'available-hub/:hubId/units',
    component: UnitsComponent,
    resolve: { reqData: AvailableHubResolver },
    runGuardsAndResolvers: 'always',
    data: <RouteData>{ page: RoutedPage.UNITS, navIndex: 3 }
  },
  {
    path: 'checkout',
    component: CheckoutComponent,
    data: <RouteData>{ page: RoutedPage.CHECKOUT, navIndex: 4 }
  },
  {
    path: '**',
    redirectTo: ''
  }
];

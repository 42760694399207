import { CommonModule, NgOptimizedImage } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import {
  BookingUnitExtra,
  extractUnitsType,
  fadeInOutAnimation,
  SearchBarSelectionInternal,
  Unit,
  UnitSelectedExtraFeatures,
  UnitType,
  UnitWithSelectedExtras
} from '@shared/';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { GalleriaModule } from 'primeng/galleria';
import { TabViewModule } from 'primeng/tabview';
import { TooltipModule } from 'primeng/tooltip';
import { BehaviorSubject } from 'rxjs';
import { ComputedPipe, PrettyNamePipe, ToStringPipe } from '../../../pipes';
import { StoreService } from '../../../services';
import { GalleryPopupComponent } from '../../common/gallery-popup/gallery-popup.component';
import { UnitDetailsComponent } from '../details/unit-details.component';
import { UnitImagesComponent } from '../images/unit-images.component';
import { UnitPriceComponent } from '../price/unit-price.component';

@Component({
  selector: 'app-units-list',
  standalone: true,
  animations: [fadeInOutAnimation],
  imports: [
    CommonModule,
    TabViewModule,
    ComputedPipe,
    DividerModule,
    ButtonModule,
    TooltipModule,
    PrettyNamePipe,
    ToStringPipe,
    GalleriaModule,
    GalleryPopupComponent,
    NgOptimizedImage,
    UnitImagesComponent,
    UnitDetailsComponent,
    UnitPriceComponent,
    BadgeModule
  ],
  templateUrl: './unit-list.component.html',
  styleUrl: './unit-list.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UnitListComponent implements OnInit {
  @Input('selectedUnitsSelectedExtras') unitsWithExtras: UnitWithSelectedExtras[] | null = null;
  @Input() selection: SearchBarSelectionInternal | null = null;
  @Input({ transform: extractUnitsType }) availableUnits!: {
    [key in UnitType]?: Unit[];
  };
  @Input({ transform: (value: string[] | null) => (!value ? [] : value) })
  set processedSelectedUnits(selectedUnitIds: string[]) {
    this.selectedUnitIds = selectedUnitIds;
    this.totalSelectedUnitsPerTypeMap = Object.entries(this.availableUnits).reduce(
      (accum: { [key in UnitType]?: number }, [type, units]: [string, Unit[]]) => {
        const subtotal = selectedUnitIds.reduce(
          (totalPerType, elem) =>
            units.find(unit => unit.id === elem) ? totalPerType + 1 : totalPerType,
          0
        );

        return {
          ...accum,
          [type]: subtotal
        };
      },
      {}
    );
  }

  public selectedUnitIds: string[] = [];
  public totalSelectedUnitsPerTypeMap: { [key: string]: number } = {};

  activeUnitSelectedExtrasSubject$: BehaviorSubject<UnitSelectedExtraFeatures[]> =
    new BehaviorSubject<UnitSelectedExtraFeatures[]>([]);

  constructor(private store: StoreService) {}

  ngOnInit() {
    this.store.selectedUnitExtraFeatureMap$.subscribe(extras =>
      this.activeUnitSelectedExtrasSubject$.next(extras)
    );
  }

  public isUnitSelected(id: string, selectedUnits: string[]): boolean {
    if (!id || !selectedUnits) return false;
    return selectedUnits.includes(id);
  }

  getSelectedExtras(unitId: string): BookingUnitExtra[] {
    const selectedUnit = this.unitsWithExtras?.find(selectedUnit => selectedUnit.id === unitId);
    if (!selectedUnit) return [];
    return [...selectedUnit.extraFeatures];
  }
}

import { Unit, UnitType } from '@shared/';

export function extractUnitsType(units: Unit[]): {
  [key in UnitType]?: Unit[];
} {
  return units.reduce((accum: { [unitType: string]: Unit[] }, elem: Unit) => {
    if (!elem.reservationUnitType || !elem.reservationUnitType.name) {
      return accum;
    }

    if (accum.hasOwnProperty(elem.reservationUnitType.name)) {
      return {
        ...accum,
        [elem.reservationUnitType.name]: [...accum[elem.reservationUnitType.name], elem]
      };
    } else {
      return {
        ...accum,
        [elem.reservationUnitType.name]: [elem]
      };
    }
  }, {});
}

import { ResolveFn } from '@angular/router';
import { inject } from '@angular/core';
import { HubService, StoreService } from '../../services';
import { mergeMap, of } from 'rxjs';

export const HubsResolver: ResolveFn<any> = () => {
  const store = inject(StoreService);
  const hubService = inject(HubService);

  return store.hubSummaries$.pipe(
    mergeMap(res => {
      if (res.length) {
        return of(res);
      } else {
        return hubService.getAllHubSummaries();
      }
    })
  );
};

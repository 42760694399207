import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { HubDateAvailabilityMap } from '@shared/';
import { ComputedPipe } from '../../../pipes';
import dayjs from 'dayjs';
import { CalendarMonthChangeEvent } from 'primeng/calendar/calendar.interface';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

interface PrimeCalendarTemplateDate {
  day: number;
  month: number;
  year: number;
  today: boolean;
  selectable: boolean;
}

@Component({
  selector: 'app-hub-calendar',
  standalone: true,
  animations: [],
  imports: [
    ButtonModule,
    CalendarModule,
    SharedModule,
    CommonModule,
    ComputedPipe,
    ProgressSpinnerModule,
    ReactiveFormsModule
  ],
  templateUrl: './hub-calendar.component.html',
  styleUrl: './hub-calendar.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HubCalendarComponent implements OnInit {
  constructor(private formBuildier: FormBuilder) {
    this.calendarForm = this.formBuildier.group({ rangeDates: [null, null] });
  }
  calendarForm: FormGroup;

  @Input() availableDates!: HubDateAvailabilityMap;
  @Input() isCalendarLoading!: boolean;
  @Output() monthChange = new EventEmitter<CalendarMonthChangeEvent>();

  ngOnInit() {}

  public getDayCapacity = ({
    date: dateObj,
    availableDates
  }: {
    date: PrimeCalendarTemplateDate;
    availableDates: HubDateAvailabilityMap;
  }): number | null => {
    if (Object.keys(availableDates).length) {
      const date = dayjs(`${dateObj.month + 1}-${dateObj.day}-${dateObj.year}`);
      const formattedDate = date.format('YYYY-MM-DD') + 'T00:00';
      return availableDates[formattedDate]
        ? availableDates[formattedDate].adultCount + availableDates[formattedDate].childrenCount
        : null;
    }
    return 0;
  };

  public isHighlighted = ({
    date: { day, month, year },
    intervalStartDate,
    intervalEndDate
  }: {
    date: PrimeCalendarTemplateDate;
    intervalStartDate: Date | null;
    intervalEndDate: Date | null;
  }): 'highlight-start' | 'highlight-end' | 'highlight-single' | '' => {
    if (!intervalStartDate && !intervalEndDate) return '';

    const dateToCompare = dayjs(`${year}-${month + 1}-${day}`);
    const startDate = intervalStartDate && dayjs(intervalStartDate).startOf('day');
    const endDate = intervalEndDate && dayjs(intervalEndDate).startOf('day');

    const isStart = dateToCompare.isSame(startDate);
    const isEnd = dateToCompare.isSame(endDate);

    switch (true) {
      case isStart && isEnd:
        return 'highlight-single';
      case isStart && !endDate:
        return 'highlight-single';
      case isEnd && !startDate:
        return 'highlight-single';
      case isStart && endDate && !isEnd:
        return 'highlight-start';
      case isEnd && startDate && !isStart:
        return 'highlight-end';
      default:
        return '';
    }
  };

  get intervalStartDate(): Date | null {
    const dateInterval = this.calendarForm.get('rangeDates')?.value;

    return (dateInterval && dateInterval[0]) ?? null;
  }

  get intervalEndDate(): Date | null {
    const dateInterval = this.calendarForm.get('rangeDates')?.value;

    return (dateInterval && dateInterval[1]) ?? null;
  }

  triggerCalendarMonthChange(event: CalendarMonthChangeEvent): void {
    this.monthChange.emit(event);
  }
}

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { RouterLink } from '@angular/router';
import {
  AutoUnsubscribe,
  fadeInOutAnimation,
  SearchBarSelectionInternal,
  Unit,
  UnitWithSelectedExtras
} from '@shared/';
import { ButtonModule } from 'primeng/button';
import { BehaviorSubject } from 'rxjs';
import { StoreService } from '../../services';
import { UnitListComponent } from './list/unit-list.component';

@Component({
  selector: 'app-units',
  standalone: true,
  animations: [fadeInOutAnimation],
  imports: [CommonModule, UnitListComponent, ButtonModule, RouterLink],
  templateUrl: './units.component.html',
  styleUrl: './units.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
@AutoUnsubscribe
export class UnitsComponent implements OnInit {
  availableUnitsSubject$: BehaviorSubject<Unit[] | null> = new BehaviorSubject<Unit[] | null>(null);
  selectedUnitsSelectedExtrasSubject$: BehaviorSubject<UnitWithSelectedExtras[] | null> =
    new BehaviorSubject<UnitWithSelectedExtras[] | null>(null);
  selectedUnitIdsSubject$: BehaviorSubject<string[] | null> = new BehaviorSubject<string[] | null>(
    null
  );
  selectionSubject$: BehaviorSubject<SearchBarSelectionInternal | null> =
    new BehaviorSubject<SearchBarSelectionInternal | null>(null);
  bookingTotalSubject$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  constructor(private store: StoreService) {}

  ngOnInit() {
    this.store.currentAvailableUnits$.subscribe(units => this.availableUnitsSubject$.next(units));
    this.store.selectedUnitsWithSelectedExtras$.subscribe(units =>
      this.selectedUnitsSelectedExtrasSubject$.next(units)
    );
    this.store.selectedUnitIds$.subscribe(unitIds => this.selectedUnitIdsSubject$.next(unitIds));
    this.store.currentSelection$.subscribe(selection => this.selectionSubject$.next(selection));
    this.store.bookingTotal$.subscribe(total => this.bookingTotalSubject$.next(total));
  }
}
